export interface URL {
  origin: string;
  path: string;
  host: string;
}

export function parseUrl(url: string): URL | null {
  if (!url) {
    return null;
  }
  if (!url.startsWith('http')) {
    return null;
  }
  const parsed = new URL(url);
  const path = (parsed.pathname)
  // Remove trailing slash
    .replace(/\/$/, '');
  const { origin, host } = parsed;

  return {
    origin,
    host,
    path,
  };
}
