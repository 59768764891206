/*
Custom sign in based off of:
https://github.com/nextauthjs/next-auth/blob/v4/packages/next-auth/src/react/index.tsx#L212
It contains only the blocks we need for the cognito sign in
with extra logic to login to authdepot first before logging into cognito
 */
import {
  getCsrfToken,
  getProviders,
  LiteralUnion,
  SignInAuthorizationParams,
  SignInOptions,
  SignInResponse,
} from 'next-auth/react';
import { BuiltInProviderType, RedirectableProviderType } from 'next-auth/providers';
import { apiBaseUrl, AuthClientConfig } from 'next-auth/client/_utils';
import { parseUrl } from '@/helpers/url';

export default async function uopSignIn<
    P extends RedirectableProviderType | undefined = undefined,
>(
  provider?: LiteralUnion<
  P extends RedirectableProviderType
    ? P | BuiltInProviderType
    : BuiltInProviderType
  >,
  options?: SignInOptions,
  authorizationParams?: SignInAuthorizationParams,
): Promise<
  P extends RedirectableProviderType ? SignInResponse | undefined : undefined
  > {
  const { callbackUrl = window.location.href } = options ?? {};

  // same as the actual NEXT_AUTH_URL .env var but its not accessible client side
  // named the same so we can keep track of it if it ever changes
  const NEXT_AUTH_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth`;
  const NEXT_AUTH_CONFIG: AuthClientConfig = {
    baseUrl: parseUrl(NEXT_AUTH_URL).origin,
    basePath: parseUrl(NEXT_AUTH_URL).path,
    baseUrlServer: parseUrl(NEXT_AUTH_URL).origin,
    basePathServer: parseUrl(NEXT_AUTH_URL).path,
    _lastSync: 0,
    _session: undefined,
    _getSession: () => {},
  };

  const baseUrl = apiBaseUrl(NEXT_AUTH_CONFIG);
  const providers = await getProviders();

  if (!providers) {
    window.location.href = `${baseUrl}/error`;
    return;
  }

  if (!provider || !(provider in providers)) {
    window.location.href = `${baseUrl}/signin?${new URLSearchParams({
      callbackUrl,
    })}`;
    return;
  }

  const isCredentials = providers[provider].type === 'credentials';
  const signInUrl = `${baseUrl}/${
    isCredentials ? 'callback' : 'signin'
  }/${provider}`;

  const authSignInUrl = `${signInUrl}${authorizationParams ? `?${new URLSearchParams(authorizationParams)}` : ''}`;

  const res = await fetch(authSignInUrl, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // @ts-expect-error
    body: new URLSearchParams({
      ...options,
      csrfToken: await getCsrfToken(),
      callbackUrl,
      json: true,
    }),
  });

  const data = await res.json();
  const { status } = res;

  if (status === 200) {
    const url = data.url ?? callbackUrl;
    // first redirect is to login to authdepot first
    const redirectUrl = new URL(process.env.NEXT_PUBLIC_AUTHDEPOT_LOGIN_URI);
    // then callback redirect to cognito login
    redirectUrl.searchParams.append('callback', url);

    window.location.href = redirectUrl.href;
    // If url contains a hash, the browser does not reload the page. We reload manually
    if (url.includes('#')) window.location.reload();
  } else {
    window.location.href = `${baseUrl}/error`;
  }
}
